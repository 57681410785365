import React, { useState } from 'react';
import styles from './index.module.scss';
import { useIsMobile } from '~/common/hooks/use-is-mobile';

export const DiscoverSection = React.memo((props: any) => {
  const { discoverIntro, discoverImage, dealerName } = props;
  const { containerRef, isMobile } = useIsMobile();

  return (
    <div ref={containerRef} className={styles.DiscoverContainer}>
      <div className={styles.ContentWrapper}>
        <div className={styles.HeaderTabs}>
          {isMobile() ? (
            <div className={styles.HeaderTabsActive}>Discover</div>
          ) : (
            <div className={styles.HeaderTabsActive}>Discover {dealerName}</div>
          )}
        </div>
        <div className={styles.ContentContainer}>
          <div className={styles.ContentBlurb}>
            <div dangerouslySetInnerHTML={{ __html: discoverIntro }} />
          </div>
          <div className={styles.ContentImage}>
            <img src={discoverImage || ''} />
          </div>
        </div>
      </div>
    </div>
  );
});
