import React from 'react';
import { BannerImageCarousel } from '../banner-image-carousel';
import { DealerDepartments } from '../dealer-departments';
import styles from './index.module.scss';

interface DealerDetailsProps {
  totalRatings?: number;
  rating?: number;
}
export const DealerDetailsSection = React.memo<DealerDetailsProps>((props) => {
  return (
    <div className={styles.dealerDetails}>
      <div className={styles.dealerDetailWrapper}>
        <DealerDepartments {...props} />
        <BannerImageCarousel />
      </div>
    </div>
  );
});
