import React from 'react';
import { RecoilRoot, MutableSnapshot } from 'recoil';
import { DealerDetailsSection } from './components/dealer-details-section';
import { DiscoverSection } from './components/discover-section';
import { MeetTheTeamSection } from './components/meet-the-team';
import { GoogleReviewSection } from './components/reviews';
import { GooglePlaceDetails } from '~/pages/find-dealer/models';
import { DealerPageState, DealerState } from './store';
import { PageLayout } from '~/common/components';
import FeatureContent from '~/macros/feature-content';
import digitalData from '~/common/services/data-layer-service';

export const Dealer = (props: DealerState) => {
  const initialize = ({ set }: MutableSnapshot) => {
    set(DealerPageState, { ...props });
  };

  const [googlePlaceDetails, setGooglePlaceDetails] =
    React.useState<GooglePlaceDetails>();

  React.useEffect(() => {
    fetch(`/api/google-api/place-details/${props.googlePlaceId}`)
      .then((res) => res.json())
      .then((res) => {
        setGooglePlaceDetails(res);
      });
  }, []);

  React.useLayoutEffect(() => {
    digitalData.pushAll([
      {
        event: '_pageLoaded',
      },
      {
        event: '_formNavigate',
        form: {
          name: 'find a dealer',
          stage: 'submit_page',
        },
        user: {
          preferredDealer: props.dealerName,
        },
      },
    ]);
  }, [props.dealerName]);

  return (
    <RecoilRoot initializeState={initialize}>
      <PageLayout>
        <DealerDetailsSection {...googlePlaceDetails} />

        {props.discoverIntro && props.discoverImage && (
          <DiscoverSection
            discoverImage={props.discoverImage}
            discoverIntro={props.discoverIntro}
            dealerName={props.dealerName}
          />
        )}

        {props.teamMembers.length > 0 && (
          <MeetTheTeamSection
            dealerName={props.dealerName}
            dealerIntroduction={props.pageInfo?.teamIntroText ?? ''}
            teamMembersList={props.teamMembers}
          />
        )}

        {!!googlePlaceDetails && googlePlaceDetails.reviews.length > 0 && (
          <GoogleReviewSection
            dealerName={props.dealerName}
            googleReviews={googlePlaceDetails.reviews}
          />
        )}
        {props.discoverMoreSection && (
          <FeatureContent {...props.discoverMoreSection} />
        )}
      </PageLayout>
    </RecoilRoot>
  );
};
