import React from 'react';
import { useRecoilValue } from 'recoil';
import { Carousel } from 'react-responsive-carousel';
import { BannerImages } from '../../store';
import styles from './index.module.scss';

export const BannerImageCarousel = React.memo(() => {
  const bannerImages = useRecoilValue(BannerImages);
  return (
    <div className={styles.bannerImagesContainer}>
      <Carousel
        swipeable
        showIndicators
        infiniteLoop
        autoPlay={false}
        showThumbs={false}
        showStatus={false}
        className={styles.bannerCarousel}
      >
        {bannerImages.map((image, index) => (
          <img
            key={index}
            src={image}
            alt={image}
            className={styles.bannerImage}
          />
        ))}
      </Carousel>
    </div>
  );
});
