import React, { useState, useLayoutEffect, useRef } from 'react';
import cn from 'classnames';
import Carousel from 'react-multi-carousel';
import { Button, SvgIcon } from '@ui-elements';
import styles from '../reviews/index.module.scss';
import { GoogleReviews } from '~/pages/find-dealer/models';

interface DealerReviews {
  googleReviews: GoogleReviews[];
  dealerName: string;
}

export const GoogleReviewSection = React.memo<DealerReviews>(
  ({ googleReviews, dealerName }) => {
    const [count, setCount] = useState<number>(3);
    const readMore = () => {
      setCount(count + 3);
    };

    return (
      <div id="dealer-google-reivews" className={styles.DealerReviewsContainer}>
        <div className={styles.DealerReviewsHeader}>
          <h1 className={styles.ReviewsHeader}>
            Read {dealerName} Google Reviews
          </h1>
        </div>

        <div>
          {Array.from({ length: count / 3 }, (_, index) => {
            return (
              <div key={index}>
                <DealerReviewsSectionDesktop
                  googleReviewList={SkipAndTakeReviews(googleReviews, index)}
                />
                <DealerReviewsSectionMobile googleReviewList={googleReviews} />
              </div>
            );
          })}
        </div>

        {googleReviews.length > 3 && googleReviews.length > count && (
          <div className={styles.DealerReviewsSectionViewMore}>
            <Button
              type="primary"
              className={styles.DealerReviewsViewMoreText}
              onClick={readMore}
              buttonSize="medium"
            >
              Read more reviews
            </Button>
          </div>
        )}
      </div>
    );
  }
);

const SkipAndTakeReviews = (reviewList: GoogleReviews[], index: number) => {
  const start = index * 3;
  const end = start + 3;
  const googleReviews = reviewList.slice(start, end);
  return googleReviews;
};

interface GoogleReviewLimitedList {
  googleReviewList: GoogleReviews[];
  count?: number;
}

export const DealerReviewsSectionDesktop = React.memo<GoogleReviewLimitedList>(
  ({ ...props }) => {
    return (
      <div className={styles.DealerReviewsSectionDesktop}>
        {props.googleReviewList?.slice(0, 3).map((currMember) => (
          <DealerReview key={currMember.authorName} {...currMember} />
        ))}
      </div>
    );
  }
);

export const DealerReviewsSectionMobile = React.memo<GoogleReviewLimitedList>(
  ({ ...props }) => {
    return (
      <div key={props.count} className={styles.DealerReviewsSectionMobile}>
        <Carousel
          className={styles.Carousel}
          arrows={false}
          showDots
          dotListClass={styles.CarouselDots}
          swipeable
          ssr={false}
          removeArrowOnDeviceType={['mobile']}
          renderDotsOutside
          responsive={{
            mobile: {
              breakpoint: { max: 540, min: 0 },
              items: 1,
              slidesToSlide: 1,
            },
          }}
        >
          {props.googleReviewList?.map((currMember) => (
            <DealerReview key={currMember.authorName} {...currMember} />
          ))}
        </Carousel>
      </div>
    );
  }
);

const DealerReview = React.memo<GoogleReviews>(({ ...props }) => {
  const valueStyle = {
    width: `${100 * (props.rating / 5)}%`,
  };

  return (
    <div className={styles.ReviewContainer}>
      <div className={styles.RatingStarsContainer}>
        <div className={styles.RatingContainer} style={valueStyle}>
          {[...Array(5)].map((_, index) => {
            return (
              <SvgIcon
                key={index}
                type="star"
                fill="none"
                size={'20px'}
                color="#1637A0"
              />
            );
          })}
        </div>
      </div>
      <ReviewText reviewText={props.reviewText} />
      <div className={styles.ReviewDetailsContainer}>
        <p className={styles.ReviewAuthorName}>{props.authorName}</p>
        <p className={styles.ReviewDate}>{props.timeDescription}</p>
      </div>
    </div>
  );
});

interface ReviewTextProps {
  reviewText: string;
}

export const ReviewText = React.memo<ReviewTextProps>(({ reviewText }) => {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const [viewAll, setViewAll] = useState<boolean>();
  const [hasViewMore, setHasViewMore] = useState<boolean>();

  useLayoutEffect(() => {
    if (!!containerRef.current) {
      if (containerRef.current.clientHeight > 100) {
        setHasViewMore(true);
      }
    }
  }, []);

  return (
    <div className={styles.ReviewTextContainer}>
      <div
        ref={containerRef}
        className={cn(styles.ReviewText, {
          [styles.WithViewMore]: hasViewMore && !viewAll,
        })}
      >
        {/* className={styles.ReviewTextContainer}> */}
        <p>{reviewText}</p>
        {hasViewMore && (
          <div
            className={styles.ViewMoreButton}
            onClick={() => {
              setViewAll(!viewAll);
            }}
          >
            {viewAll ? (
              <>
                <span>View less</span>
                <SvgIcon
                  type="chevronUp"
                  color="#323334"
                  size="12px"
                  style={{ verticalAlign: 'text-top' }}
                />
              </>
            ) : (
              <>
                <span>View more</span>
                <SvgIcon
                  type="chevronDown"
                  color="#323334"
                  size="12px"
                  style={{ verticalAlign: 'text-top' }}
                />
              </>
            )}
          </div>
        )}
      </div>
    </div>
  );
});
