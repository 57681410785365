import React, { useState } from 'react';
import styles from '../meet-the-team/index.module.scss';
import { SvgIcon } from '@ui-elements';

interface MeetTheTeamDetails {
  dealerName: string;
  dealerIntroduction: string;
  teamMembersList: TeamMemberDetails[];
}

interface TeamMemberDetails {
  id: string;
  photo?: string;
  firstName: string;
  lastName: string;
  role: string;
  phone?: string;
  email?: string;
  showContactDetails: boolean;
}

export const MeetTheTeamSection = React.memo<MeetTheTeamDetails>(
  ({ dealerName, dealerIntroduction, teamMembersList }) => {
    const [count, setCount] = useState<number>(3);

    return (
      <div className={styles.MeetTheTeamContainer}>
        <div className={styles.TeamHeader}>
          <h1 className={styles.MeetYourHeader}>Meet the {dealerName} team</h1>
          <span className={styles.MeetYourDescription}>
            {dealerIntroduction}
          </span>
        </div>
        <>
          {teamMembersList.length > 0 && (
            <div className={styles.TeamMembersSection}>
              {teamMembersList?.slice(0, count).map((currMember) => (
                <TeamMember key={currMember.id} {...currMember} />
              ))}
            </div>
          )}
        </>
        {teamMembersList.length > 3 && teamMembersList.length > count && (
          <div className={styles.TeamMembersSectionViewMore}>
            <a
              className={styles.TeamMemberViewMoreText}
              onClick={() => {
                setCount(count + 3);
              }}
            >
              View More
              <SvgIcon
                type="chevronDown"
                size={1.15}
                style={{
                  padding: '0px 0px 1px 8px',
                  alignSelf: 'start',
                  lineHeight: 'unset',
                }}
              />
            </a>
          </div>
        )}
      </div>
    );
  }
);

export const TeamMember = React.memo<TeamMemberDetails>(({ ...props }) => {
  return (
    <div className={styles.TeamMemberContainer}>
      <img className={styles.TeamMemberImage} src={props.photo} />
      <div className={styles.TeamMemberBasicInfo}>
        <div className={styles.TeamMemberNamePos}>
          <span className={styles.TeamMemberName}>
            {props.firstName} {props.lastName}
          </span>
          <span className={styles.TeamMemberPostion}> | {props.role}</span>
        </div>
        <div className={styles.TeamMemberContactInfo}>
          {props.showContactDetails && props.phone && (
            <div className={styles.TeamMemberPhoneNumber}>
              <SvgIcon
                type="phone"
                size={1}
                style={{
                  padding: '0px 10px 1.5px 0px',
                  alignSelf: 'start',
                  lineHeight: 'unset',
                }}
              />
              <span className={styles.hideMobile}>{props.phone}</span>
              <a className={styles.showMobile} href={`tel:${props.phone}`}>
                {props.phone}
              </a>
            </div>
          )}
          {props.showContactDetails && props.email && (
            <div className={styles.TeamMemberEmail}>
              <SvgIcon
                type="envelopeSolid"
                size={1}
                style={{
                  padding: '0px 10px 1.5px 0px',
                  alignSelf: 'start',
                  lineHeight: 'unset',
                }}
              />
              <a href={`mailto:${props.email}`}>{props.email}</a>
            </div>
          )}
        </div>
      </div>
    </div>
  );
});
